<template>
    <div v-if="$route.path !== '/auth/login'">
        <Sticky>
            <nav class="navbar navbar-horizontal navbar-expand-lg bg-dark">
                <div class="container-fluid">
                    <div class="navbar-brand text-white pt-3 pb-3 d-flex justify-content-between" style="width: 100%">
                        <div class="col-3 banking-icons my-auto ml-1 mr-3" v-if="$route.path !== '/auth/login'">
                            <i class="fas fa-chevron-left mx-auto my-1 text-xl" @click="$router.push('/home')" v-if="$route.path !== '/home'"></i>
                        </div>
                        <div class="col-9 text-center d-flex">
                            <h1 class="text-success pt-2">Zaw Tun Family</h1>
                        </div>
                    </div>
                </div>
            </nav>
        </Sticky>
    </div>
</template>
<script>
import { Toast, Dialog, Sticky } from 'vant';

export default {
  components: { Sticky },
  data() {
    return {
      imageLink: `https://ui-avatars.com/api/?name=${
        this.$store.state.authUser.email
      }&background=0D8ABC&color=fff`,
    };
  },
  methods: {
    closeNavCollapse() {
      $('#navbar-default').collapse('hide');
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      $('#navbar-default').collapse('hide');
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
      $('#navbar-default').collapse('hide');
    },
    redirectRoute(path) {
      if (path === '2d') {
        this.$router.push('/user/2d');
      } else {
        this.$router.push('/user/3d');
      }
      return true;
    },
  },
};
</script>
<style scoped>
.navbar {
    padding: 3px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    /* border-bottom: 2px solid #000000; */
}
.navbar-horizontal .navbar-brand img {
    height: 50px;
}
.nav-link {
    cursor: pointer;
}
.navbar-brand.brand-text {
    font-size: 20px;
    color: red;
    margin-left: 0px;
    margin-right: 10px;
}
.navbar-brand {
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.dropdown-item {
    cursor: pointer;
}
.bg-default {
    background-color:#471416 !important;
}
@media (max-width: 767.98px) {
    .navbar-brand {
        font-size: 14px;
        margin-left: 0px;
        margin-right: 0px;
    }
}
</style>
