<template>
    <div style="background: #e0ffdd !important">
        <!-- <img src="/build/assets/img/brand/khit_thit.png" alt="" /> -->
        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar"
            scrollable
            text="တွဲဖက်ညီညီ ZT Family"
        />

        <div class="px-3 mx-auto p-0 d-flex main-image-wrapper mt-2 pt-2">
            <b-carousel
                id="carousel-1"
                :interval="3000"
                controls
                fade
                style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%;"
            >
                <b-carousel-slide
                    img-src="/build/assets/img/theme/main1.png"
                ></b-carousel-slide>

                <b-carousel-slide
                    img-src="/build/assets/img/theme/main2.png"
                ></b-carousel-slide>
            </b-carousel>
        </div>

        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar mt-3 mb-5"
            scrollable
            text="မဂ်လာပါ မြန်မာငွေ ၁၅၀၀ကျပ်နဲ့ အကောင့်ဖွင့်ကစားရန်။"
        />

        <div class="justify-content-center cards-container-mobile pb-5">
                <div
                    class="col-xl-4 col-4 home-cards mb-1 pr-0"
                >
                    <a href="tel:+959444123994">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/phone.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>Phone <br> ခေါ်ဆိုရန်။</b></span>
                        </div>
                    </a>
                </div>
                <div
                    class="col-xl-4 col-4 home-cards mb-1 pr-0"
                >
                    <a href="https://www.facebook.com/profile.php?id=100067591416292&mibextid=ZbWKwL">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/facebook.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>Facebook <br>မှဆက်သွယ်ရန်။</b></span>
                        </div>
                    </a>
                </div>
                <div
                    class="col-xl-4 col-4 home-cards mb-1"
                >
                    <a href="viber://contact?number=%2B959754702082">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/viber.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>Viber <br>မှဆက်သွယ်ရန်။</b></span>
                        </div>
                    </a>
                </div>

                <div
                    class="col-xl-4 col-4 home-cards mb-1 pr-0"
                >
                    <a href="https://m.batman688.com/">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/batman.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>Batman <br> တိုက်ရိုက်လင့်။</b></span>
                        </div>
                    </a>
                </div>

                <div
                    class="col-xl-4 col-4 home-cards mb-1 pr-0"
                >
                    <a href="viber://contact?number=%2B959760945678">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/viber.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mt-2 mx-auto text-center"><b>လက်ခွဲရယူရန်။</b></span>
                        </div>
                    </a>
                </div>

                <div
                    class="col-xl-4 col-4 home-cards mb-1"
                >
                    <a href="https://m.bet555mix.com">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/555mix.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>555Mix <br> တိုက်ရိုက်လင့်။</b></span>
                        </div>
                    </a>
                </div>

                <div
                    class="col-xl-4 col-4 home-cards mb-1 pr-0"
                >
                    <a href="viber://contact?number=%2B959754702082">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/2d.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>2D ထိုးရန်။</b></span>
                        </div>
                    </a>
                </div>

                <div
                    class="col-xl-4 col-4 home-cards mb-1 pr-0"
                >
                    <a href="viber://contact?number=%2B959754702082">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/555mix.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>555Mix <br>အကောင့်ဖွင့်ကစားရန်။</b></span>
                        </div>
                    </a>
                </div>

                <div
                    class="col-xl-4 col-4 home-cards mb-1 pb-5"
                >
                    <a href="https://m.aiscore.com">
                        <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                            <img
                                src="/build/assets/img/theme/footballScore.png"
                                alt=""
                                class="mx-auto"
                                style="width: 70px; height: 70px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                            />
                            <span class="mx-auto text-center mt-2"><b>ဂိုးရလဒ်များ။</b></span>
                        </div>
                    </a>
                </div>
        </div>

        <van-overlay :show="isLoading" @click="isLoading = false">
            <div class="wrapper" @click.stop>
              <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
            </div>
        </van-overlay>

    </div>
</template>
<script>
import { NoticeBar, Dialog } from 'vant';

export default {
  components: { NoticeBar },
};
</script>
<style scoped>
/* .cards-container-mobile{
    background-image: url('./../../../public/build/assets/img/theme/main1.png');
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4;
} */
.card {
    cursor: pointer;
}
.main-image-wrapper img {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.video-container {
    height: 500px;
    width: 100%;
}
.notice-bar {
    margin-top: -40px;
    z-index: 1;
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: yellow !important;
    background: rgba(255, 242, 0, 0.7);
    /* border-top: 1px solid yellow;
    border-bottom: 1px solid yellow; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.twod-threed-cards {
    height: 200px;
}
.live-number {
    font-weight: bold;
    font-size: 25px;
}
.live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.not-live-text {
    font-weight: bold;
    font-size: 24px;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
}
.card-body {
    padding: 0px !important;
}
.cards-container-mobile {
    display: none;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

@keyframes live-number-animation {
    from {
        color: white;
    }
    to {
        color: red;
    }
}

@media (max-width: 768px) {
    /* .middle-container {
        padding-top: 0.1rem !important;
    } */
    hr {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .video-container {
        height: 300px;
        width: 100%;
    }
    .main-wrapper {
        padding: 7px !important;
    }
    .notice-bar {
        margin-top: 0px;
        height: 35px;
        z-index: 1;
        font-size: 12px;
        color: black !important;
        background: #2BC584;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    }
    .live-number {
        font-weight: bold;
        font-size: 20px;
    }
    .live-result {
        font-weight: bolder;
        font-size: 37px;
    }

    .cards-container {
        display: none;
    }
    .cards-container-mobile {
        padding: 0px !important;
        margin-top: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }
    .home-cards {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-body {
        padding: 0px !important;
    }
    .not-live-text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .account-container-wrapper {
        width: auto;
        height: auto;
        border-radius: 3px;
        padding: 4px;
        /* margin: 10px; */
        color: rgb(29,2,0);
        background: #F5C065;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    }
    .top-wrapper,
    .bottom-wrapper {
        padding: 10px;
        width: 100%;
        display: flex;
    }
    .top-right,
    .bottom-right {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: end;
    }
    .top-left,
    .bottom-left {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: start;
    }
}
</style>
