<template>
    <div>
       <NavBar />
        <router-view class="middle-container"> </router-view>
        <!-- <Footer /> -->
          <!-- <div class="col-4">
                <div
                    class="modal fade"
                    id="welcome"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    @click="startAudioPlayback()"
                >
                  <div
                      class="modal-dialog modal- modal-dialog-centered"
                      role="document"
                  >
                      <div class="modal-content bg-dark">
                        <div class="text-center pt-4 mx-auto p-1 col-11">
                          <img src="/build/assets/img/theme/ads.jpg" alt="" style="width: 100%; height: auto; border-radius: 5px;">
                        </div>
                          <div class="modal-body mt-2 mb-4 mx-3 text-lg text-center" style="color: gold; font-size: 14rem; font-weight: bolder;">
                            {{
                                $store.state.language === "en"
                                    ? "Warmly welcome from Yoe Yar website."
                                    : "ရိုးရာ မှနွေးထွေးစွာကြိုဆိုပါတယ်။"
                            }}
                            <br><br>
                              <p class="text-white">2D, 3D, မောင်း, ဘော်ဒီ, ဖဲဂိမ်းများနှင့် Slot ဂိမ်းများစုံလင်သော <br> မြန်မာတို့အတွက်ရိုးရာ Application</p>
                              <span class="text-dark text-sm">
                              <button class="btn btn-primary" @click="startAudioPlayback()">
                                {{
                                    $store.state.language === "en"
                                        ? "Okay"
                                        : "ဟုတ်ပြီ။"
                                }}
                              </button>
                            </span>
                          </div>
                      </div>
                  </div>
              </div>
          </div> -->


        <audio controls ref="audioPlayer">
        <!-- Replace 'your-audio-file.mp3' with the path to your audio file -->
        <source src="/build/assets/music/SaKaWarMyay.mp3" type="audio/mpeg">
        Your browser does not support the audio element
      </audio>
    </div>
</template>
<script>
import { Dialog } from 'vant';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getFBOrder']),
  },

  methods: {
    ...mapMutations(['setWholeFBMatches']),
    ...mapActions(['fetchUser']),
    generateCart() {
      if(this.$route.path.includes('2d')){
          return this.$store.state.twod_slip_data.length == 0 ? false : true
      } else if(this.$route.path.includes('3d')){
          return this.$store.state.threed_slip_data.length == 0 ? false : true
      } else {
          return this.$store.state.fb_order.fb_matches.length == 0 ? false : true
      }
    },
    startAudioPlayback() {
      // Access the audio element using the ref attribute
      const audio = this.$refs.audioPlayer;
      
      // Start audio playback
      audio.play();
      $('#welcome').modal('hide');
    },
    closeModalOutside(event) {
      if (event.target.classList.contains('modal')) {
        this.$router.go();
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        console.log('this.$store.state.androidVersion', this.$store.state.androidVersion);
        // if(res.data.data[0].androidVersion !== this.$store.state.androidVersion) {
        //   $('#version').modal('show');
        // }
      } catch (error) {
        console.log(error);
      }
    },
    openModalVoucher() {
      const type = this.$route.path.includes('2d') || this.$route.path.includes('3d') ? 'number' : '';
      if (type === 'number') {
        $('#mobile-voucher').modal('show');
      } else if (this.$store.state.fb_order.fb_matches.length === 0) {
        const dataError = this.$store.state.language === 'en'
          ? 'Please select some teams!'
          : 'အသင်းတစ်ချို့ ရွေးပေးပါ။';
        this.dialog(dataError);
      } else {
        const matches = this.getFBOrder.fb_matches;
        if (!this.$store.state.betAmount) {
          $('#amount').modal('show');
        } else {
          for (let i = 0; i < matches.length; i += 1) {
            matches[i].bet_amount = this.$store.state.betAmount;
          }
          this.setWholeFBMatches(matches);
          $('#mobile-voucher').modal('show');
        }
      }
    },

    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style scoped>
.cart-number {
    top: -2px !important;
    left: -2px !important;
}
.cart-number {
    position: absolute;
    top: -3px;
    left: -3px;
    text-align: center;
}
.middle-container {
    min-height: 75vh;
}
audio {
  display: none; /* Hide the audio player visually */
}
@media (max-width: 768px) {
    .cart-container {
        font-size: 30px !important;
    }
    .social-contacts div {
        width: 60px !important;
        height: 60px !important;
        margin-top: 15px;
    }
    #social-contacts .badge-circle.badge-md {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
    .cart-container {
        visibility: visible;
        font-size: 16px;
    }
    .middle-container {
        min-height: 92vh;
        margin: 0px !important;
        /* padding-top: 0.1rem !important; */
        /* margin-bottom: 50px !important; */
    }
}
</style>
